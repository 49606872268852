@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@300;400;500;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+TC:wght@300;400;500;600;700&display=swap');

* {
  transition: all 0.25s ease-in-out;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("./assets/fonts/helveticaneue_medium-webfont.woff") format("woff2"),
    url("./assets/fonts/helveticaneue_medium-webfont.woff2") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'edukai';
  src: url('./assets/fonts/edukai.woff2') format('woff2'),
    url('./assets/fonts/edukai.woff') format('woff'),
    url('./assets/fonts/edukai.otf') format('otf'),
    url('./assets/fonts/edukai.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
  unicode-range: U+2E00-FFFF;
}

@font-face {
  font-family: 'edusong';
  src: url('./assets/fonts/edusong.woff2') format('woff2'),
    url('./assets/fonts/edusong.woff') format('woff'),
    url('./assets/fonts/edusong.otf') format('otf'),
    url('./assets/fonts/edusong.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
  unicode-range: U+2E00-FFFF;
}

@font-face {
  font-family: 'edusongen';
  src: url('./assets/fonts/edusong.woff2') format('woff2'),
    url('./assets/fonts/edusong.woff') format('woff'),
    url('./assets/fonts/edusong.otf') format('otf'),
    url('./assets/fonts/edusong.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

$bg-green: #f6faf4;
$light-green: #99d074;
$normal-green: #56a920;
$top-bar-height: 75px;
$light-orange: #faf3e7;
$normal-orange: #f6a866;
$dark-orange: #e78825;

body {
  background-color: $bg-green;
  font-family: "Noto Sans TC", sans-serif;
  // overflow: hidden;
  // height: 100vh;
}

h1 {
  margin: unset;
}

.no-a-style {
  text-decoration: none;
  color: black;
}

h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.1em;
  color: #333333;
}

.scroller {
  overflow: scroll;
}

.site-name-left-padding {
  padding-left: 36px;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.version {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  bottom: 0.5em;
  align-items: flex-end;
  justify-content: flex-end;
  opacity: 0.25;
  pointer-events: none;
  right: 0.5em;
  z-index: 999;
}

.force-width {
  min-width: unset;
}

.top-bar {
  background-color: white;
  box-shadow: 0px 3px 4px rgba(211, 210, 203, 0.3);
  height: $top-bar-height;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: max(100%, 100vw);
  // min-width: 1024px;
  top: 0;
  z-index: 100;
}

.top-bar-menu {
  gap: 10px;
  display: flex;
  align-items: center;
  margin-right: 12px;
}

.text-button {
  cursor: pointer;
  padding: 6px;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0.1em;
  border-radius: 2em;
}

.text-button:hover {
  font-weight: 900;
}

.search-backdrop {
  height: 36px;
  background-color: $light-orange;
  border-radius: 36px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  align-items: center;
  gap: 6px;
}

#search {
  border: 0px;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0.1em;
  width: 100%;
  color: $normal-orange;
  background-color: transparent;
}

#search::placeholder {
  border: 0px;
  color: $normal-orange;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0.1em;
  background-color: transparent;
}

#search:focus {
  outline: none;
}

.content-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.align-item-center {
  align-items: center;
}

.landing-content-title-parent {
  position: relative;
  padding: 0px 4px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-self: center;
}

#content-title {
  font-style: normal;
  font-weight: 700;
  font-size: min(38px, min(10vw, 5vh));
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.22em;
  color: #005a03;
  z-index: 10;
  text-indent: 0.22em;
  position: inherit;
  justify-content: center;
}

#content-title.large {
  font-size: 44px;
  line-height: 22px;
}

#content-title.blue {
  color: #1F5194;
}

#content-title.purple {
  color: #5D4890;
}

#content-title.grey {
  color: #784618;
}

#bottom-outline {
  height: 16px;
  background-color: #ffe240;
  padding: 0px 4px;
  width: 100%;
  border-radius: 1px;
  bottom: -20px;
  position: absolute;
  z-index: 1;
}

#bottom-outline.large {
  height: 12px;
  bottom: -14px;
}

.landing-ability-options-parent {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  position: relative;
}

.landing-ability-img-parent {
  width: 50%;
  height: 65vh;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5em;
}

.landing-ability-img {
  height: 90%;
  width: 90%;
  max-width: 512px;
  max-height: 512px;
  object-fit: contain;
  object-position: top;
}

.orange-button {
  justify-content: center;
  box-sizing: border-box;
  height: 50px;
  padding: 0px 24px;
  background: #f7764e;
  border: 2px solid #ebab41;
  border-radius: 58px;
  font-weight: 700;
  font-size: 17px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.6em;
  text-indent: 0.6em;
  color: #ffffff;
  cursor: pointer;
  text-decoration: unset;
  white-space: nowrap;
  width: fit-content;
}

.orange-button:hover {
  color: #eb6041;
  background: transparent;
  border: 2px solid #ff8368;
}

.red-button {
  justify-content: center;
  box-sizing: border-box;
  height: 36px;
  padding: 0px 16px;
  background: #EB6041;
  border: 2px solid #F18D77;
  border-radius: 58px;
  font-weight: 700;
  font-size: 17px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.6em;
  text-indent: 0.6em;
  color: #ffffff;
  cursor: pointer;
  width: fit-content;
  text-decoration: unset;
}

.red-button:hover {
  color: #EB6041;
  background: transparent;
  border: 2px solid #F18D77;
}

.green-button {
  white-space: nowrap;
  justify-content: center;
  box-sizing: border-box;
  height: 50px;
  padding: 0px 12px;
  background: #56a920;
  border: 2px solid #99d074;
  border-radius: 58px;
  font-weight: 700;
  font-size: 17px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.6em;
  text-indent: 0.6em;
  color: #ffffff;
  cursor: pointer;
  text-decoration: unset;
}

.green-button:hover {
  color: #2a872d;
  background: transparent;
  border: 2px solid #56a920;
}

.blue-button {
  white-space: nowrap;
  justify-content: center;
  box-sizing: border-box;
  height: 50px;
  padding: 0px 12px;
  background: #1969BF;
  border: 2px solid #57ACDC;
  border-radius: 58px;
  font-weight: 700;
  font-size: 17px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.6em;
  text-indent: 0.6em;
  color: #ffffff;
  cursor: pointer;
  text-decoration: unset;
}

.blue-button:hover {
  color: #0F58A7;
  background: transparent;
  border: 2px solid #1867BB;
}

.purple-button {
  white-space: nowrap;
  justify-content: center;
  box-sizing: border-box;
  height: 50px;
  padding: 0px 12px;
  background: #8366B2;
  border: 2px solid #AE92DD;
  border-radius: 58px;
  font-weight: 700;
  font-size: 17px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.6em;
  text-indent: 0.6em;
  color: #ffffff;
  cursor: pointer;
  text-decoration: unset;
}

.purple-button:hover {
  color: #613895;
  background: transparent;
  border: 2px solid #825EBC;
}

.grey-button {
  white-space: nowrap;
  justify-content: center;
  box-sizing: border-box;
  height: 50px;
  padding: 0px 12px;
  background: #D4951B;
  border: 2px solid #ECC990;
  border-radius: 58px;
  font-weight: 700;
  font-size: 17px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.6em;
  text-indent: 0.6em;
  color: #ffffff;
  cursor: pointer;
  text-decoration: unset;
}

.grey-button:hover {
  color: #D4951B;
  background: transparent;
  border: 2px solid #D4951B;
}

.grey-button.secondary {
  background: #F2E9D9;
  color: #B97A66;
  border: 2px solid #D4951B;
}

.grey-button.secondary:hover {
  background: #B97A66;
  color: #F2E9D9;
  border: 2px solid #F2E9D9;
}

.green-button.secondary {
  justify-content: center;
  box-sizing: border-box;
  height: 50px;
  padding: 0px 12px;
  background: #DEF0EC;
  border: 2px solid #8CC4B6;
  border-radius: 58px;
  font-weight: 700;
  font-size: 17px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.6em;
  text-indent: 0.6em;
  color: #4F9A88;
  cursor: pointer;
  text-decoration: unset;
}

.green-button.secondary:hover {
  color: white;
  background: #4F9A88;
  border: 2px solid #DEF0EC;
}

.blue-button.secondary {
  justify-content: center;
  box-sizing: border-box;
  height: 50px;
  padding: 0px 12px;
  background: #DEEEF8;
  border: 2px solid #8CA2C3;
  border-radius: 58px;
  font-weight: 700;
  font-size: 17px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.6em;
  text-indent: 0.6em;
  color: #4A7AA7;
  cursor: pointer;
  text-decoration: unset;
}

.blue-button.secondary:hover {
  color: white;
  background: #4A7AA780;
  border: 2px solid #8CA2C3;
}

.purple-button.secondary {
  justify-content: center;
  box-sizing: border-box;
  height: 50px;
  padding: 0px 12px;
  background: #DEDEF8;
  border: 2px solid #A18CC3;
  border-radius: 58px;
  font-weight: 700;
  font-size: 17px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.6em;
  text-indent: 0.6em;
  color: #8373A5;
  cursor: pointer;
  text-decoration: unset;
}

.purple-button.secondary:hover {
  color: white;
  background: #8373A580;
  border: 2px solid #DEDEF8;
}

.hori-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 0.35em;
  width: 100%;
  max-width: 100%;
  justify-content: center;
  padding: 0.35em;
  box-sizing: border-box;
}

.vertical-divider {
  border-right: 1px dashed #b0bfa9;
  left: 50%;
  height: 100%;
  position: absolute;
}

#container {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.numbering {
  height: 24px;
  width: 24px;
  min-width: 24px;
  border-radius: 24px;
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  vertical-align: middle;
  color: white;
  letter-spacing: 0em;
}

.numbering.green {
  background-color: #54ab5f;
}

.numbering.blue {
  background-color: #3D90DA;
}

.numbering.purple {
  background-color: #AB6C99;
}

.numbering.grey {
  background-color: #DA8333;
}

.numbering-parent {
  display: flex;
  gap: 1em;
  letter-spacing: 0.15em;
}

.rule-backdrop {
  background: white;
  box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  gap: 1.5em;
  padding: 2.5em 3em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 768px;
  margin: 0em 1em;
}

.hori-divider {
  height: 1px;
  width: 100%;
  border-bottom: 1px dashed #c4c4c4;
  opacity: 0.5;
}

.rule-line {
  display: flex;
  align-items: center;
  gap: 12px 6px;
  white-space: nowrap;
  flex-flow: row wrap;
  align-content: space-between;
}

.rule-a {
  background: #589e2a;
  border-radius: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.4em;
  text-indent: 0.4em;
  padding: 0.25em 0.5em;
  color: white;
}

.rule-b {
  background: #d8eacf;
  border-radius: 10px;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.4em;
  text-indent: 0.4em;
  padding: 0.25em 0.5em;
}

.rule-title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.55em;
  text-indent: 0.55em;
  color: #005a03;
}

.title-overflow-y {
  line-height: 0px;
  z-index: 10;
}

.question-with-answer-parent {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0em;
  color: #333333;
  text-align: justify;
  scroll-margin-top: 75px;
}

.question-with-answer-number {
  flex: 0 0 32px;
  font-size: 18px;
  line-height: 32px;
  text-align: end;
}

.question-with-answer-question-parent {
  display: flex;
  flex-direction: column;
  gap: 0.75em;
  letter-spacing: 0.1em;
  font-weight: 500;
  width: 100%;
}

.question-with-answer-question {
  font-family: 'edusong';
  line-height: 1.75em;
  font-size: 20px;
}

.question-with-answer-question2 {
  font-family: 'genryumin';
  line-height: 1.75em;
  font-size: 20px;
}

.question-with-answer-answer {
  display: flex;
  gap: 1em;
  font-family: 'edusong';
  align-items: center;
  cursor: pointer;
}

.question-with-answer-answer-parent {
  display: flex;
  gap: 0.75em;
  flex-direction: column;
  padding-top: 0.5em;
  font-size: 18px;
}

.question-with-answer-answer .answer-ball {
  // font-family: 'Times New Roman', Times, serif;
  font-family: 'edusongen';
  background-color: #F1F3F0;
  height: 28px;
  width: 28px;
  min-width: 28px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0px;
}

.answer-line {
  font-size: 20px;
}

.question-with-answer-answer:hover .answer-line {
  text-decoration: underline;
}

.question-with-answer-answer:hover .answer-ball {
  background-color: #49A88680;
  color: white;
}

.question-with-answer-answer .answer-ball-selected {
  background-color: #49A886;
  color: white;
}

.question-with-answer-answer .answer-ball-selected.blue {
  background-color: #397BB8;
}

.question-with-answer-answer .answer-ball-selected.wrong {
  background-color: #EF421C;
}

.question-with-answer-answer:hover .answer-ball.blue {
  background-color: #397BB880;
}

.question-with-answer-answer .answer-ball-selected.blue {
  background-color: #397BB8;
}

.question-with-answer-answer:hover .answer-ball.purple {
  background-color: #7E69BB80;
}

.question-with-answer-answer .answer-ball-selected.purple {
  background-color: #7E69BB;
}

.question-with-answer-answer:hover .answer-ball.grey {
  background-color: #DC8F6380;
}

.question-with-answer-answer .answer-ball-selected.grey {
  background-color: #DC8F63;
}

.no-answer {
  pointer-events: none;
}

.question-with-answer-description {
  font-family: 'edukai';
  font-size: 21px;
  letter-spacing: 0.18em;
  white-space: pre-wrap;
  line-height: 1.5em;
  color: black;
  font-weight: 500;
}

.question-with-answer-description2 {
  font-family: 'kaiti';
  font-size: 21px;
  letter-spacing: 0.18em;
  white-space: pre-wrap;
  line-height: 1.5em;
  color: black;
  font-weight: 500;
}

.question-with-answer-backdrop {
  background: white;
  box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.05);
  min-width: min(100%, 768px);
  border-radius: 5px;
  gap: 1.5em;
  // padding: 2.5em 3em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // min-width: 512px;
  max-width: 1024px;
  margin: 0em 1em;
  box-sizing: border-box;
  display: block;
  font-family: 'Times New Roman', Times, serif;
}

.question-with-answer-parent.article {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.question-with-answer-parent.article.maximize {
  animation: question-with-answer-parent-article-maximize 0.25s ease-in;
  grid-template-columns: 2fr 1fr;
}

.question-with-answer-parent.article.minimize {
  animation: question-with-answer-parent-article-minimize 0.25s ease-in;
  grid-template-columns: 1fr 2fr;
}

@keyframes question-with-answer-parent-article-maximize {
  0% {
    grid-template-columns: 1fr 2fr;
  }

  100% {
    grid-template-columns: 2fr 1fr;
  }
}


@keyframes question-with-answer-parent-article-minimize {
  0% {
    grid-template-columns: 2fr 1fr;
  }

  100% {
    grid-template-columns: 1fr 2fr;
  }
}

.pencil {
  width: 175px;
  transform: translateY(2em);
  margin-right: 1em;
}

.fixed {
  position: fixed;
}

.flex-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.button-fit {
  width: fit-content;
}

.pencil-stick {
  background-color: #D8EBCF;
  padding-bottom: 1em;
  padding-left: 1em;
  padding-right: 1em;
  border-radius: 5px 5px 0px 0px;
  width: 100%;
  box-sizing: border-box;
}

.pencil-stick.blue {
  background-color: #B8DFF4;
}

.pencil-stick.purple {
  background-color: #D8D0F0;
}

.pencil-stick.grey {
  background-color: #E7E7E7;
}

.pencil-title {
  // font-family: 'genryumin';
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.55em;
  color: #005A03;
  transform: translateY(-15px);
}

.pencil-title.blue {
  color: #1F5194;
}

.pencil-title.purple {
  color: #5D4890;
}

.pencil-title.grey {
  color: #5E5E5E;
}

.pencil-preview-answer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1.5em;
  row-gap: 1em;
  overflow-y: scroll;
  max-height: calc(100vh - 360px);
}

.pencil-preview-answer::-webkit-scrollbar {
  width: 4px;
}

.pencil-preview-answer::-webkit-scrollbar-track {
  background: #BCDBAD;
  border-radius: 12px;
}

.pencil-preview-answer.blue::-webkit-scrollbar-track {
  background: #96CDEB;
}

.pencil-preview-answer.purple::-webkit-scrollbar-track {
  background: #CABFEC;
}

.pencil-preview-answer.grey::-webkit-scrollbar-track {
  background: #CFCFCF;
}

.pencil-preview-answer::-webkit-scrollbar-thumb {
  background: #005A0340;
  border-radius: 12px;
}

.pencil-preview-answer::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.pencil-preview-answer-number {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #BCDBAD;
  color: black;
  display: flex;
  font-size: 14px;
  justify-content: center;
  align-items: center;
}

.pencil-preview-answer-number.blue {
  background: #96CDEB;
}

.pencil-preview-answer-number.purple {
  background: #CABFEC;
}

.pencil-preview-answer-number.grey {
  background: #D8D8D8;
}

.pencil-preview-answer-answer {
  font-size: 16px;
  width: 16px;
}

.pencil-preview-answer-answer.wrong {
  // text-decoration: line-through;
  color: #EF421C;
}

.pencil-preview-answer-parent {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75em;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}

.hori-center {
  position: relative;
  display: flex;
  gap: 2em;
  justify-content: center;
  align-items: flex-start;
}

.m-m-t {
  margin-top: 1em;
}

.m-l-t {
  margin-top: 2em;
}

.m-xl-t {
  margin-top: 4em;
}

.m-xxl-t {
  margin-top: 6em;
}

.m-m-l {
  margin-left: 1em;
}

.m-l-l {
  margin-left: 2em;
}

.p-m-t {
  padding-top: 1em;
}

.p-l-t {
  padding-top: 2em;
}

.p-l-b {
  padding-bottom: 2em;
}

.abs {
  position: absolute;
}

.fix {
  position: fixed;
}

.rel {
  position: relative;
}

.tl0 {
  top: 0;
  left: 0;
}

.bl0 {
  bottom: 0;
}

.pencil-animation-parent {
  bottom: 0;
  width: 175px;
  height: 36px;
}

.pencil-animation-img {
  width: 50%;
  height: 100%;
  object-fit: cover;
}

.blocker {
  background: rgba(51, 51, 51, 0.8);
  width: 100vw;
  height: 100vh;
  top: 0;
  position: fixed;
  left: 0;
  z-index: 150;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-container {
  background: white;
  mix-blend-mode: normal;
  max-width: 768px;
  box-shadow: 0px -3px 45px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  box-sizing: border-box;
  outline-offset: -4px;
}

.dialog-container.green {
  border: 3px solid #86C348;
  outline: 3px solid #86C348;
}

.dialog-container.blue {
  border: 3px solid #4A8FCF;
  outline: 3px solid #4A8FCF;
}

.dialog-container.purple {
  border: 3px solid #A781CE;
  outline: 3px solid #A781CE;
}

.dialog-container.grey {
  border: 3px solid #B0B0B0;
  outline: 3px solid #B0B0B0;
}

.dialog-green-content {
  background-color: white;
  width: 100%;
  border-radius: 50% 50% 10px 10px;
  text-align: center;
  padding: 0em 2em 1em 2em;
  box-sizing: border-box;
  height: fit-content;
}

.dialog-header-img {
  width: 100%;
  height: 128px;
  object-fit: cover;
}

.dialog-header-char {
  position: absolute;
  right: 2em;
  top: 0;
  height: 125px;
  transform: translateY(-45px);
}

.dialog-header-title {
  position: absolute;
  left: 1.75em;
  top: 0.75em;
  font-weight: 700;
  font-size: 2em;
  line-height: 36px;
  letter-spacing: 0.15em;
  color: white;
}

.dialog-content {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15em;
  color: #333333;
}

.dialog-content-mention {
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.15em;
  color: #333333;
}

.dialog-buttons-parent {
  display: flex;
  justify-content: space-evenly;
  gap: 1.5em;
  margin-top: 3em;
  margin-bottom: 1em;
}

.fade-in {
  animation: fade-in 0.2s ease-in;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.circle {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  background: url(./assets/images/sine.svg);
  background-size: 50px 20px;
  background-position: 0 0;
  background-repeat: repeat-x;
  animation: move 3s linear infinite;
  transform: translateY(20px);
  opacity: 0.25;
}

@keyframes move {

  0% {
    background-position: 0 0;
    transform: translateY(15px);
  }

  50% {

    transform: translateY(25px);
  }

  100% {
    background-position: -50px 0;
    transform: translateY(15px);
  }

}

.dull {
  opacity: 0.25;
}

.no-h-scroll {
  overflow: hidden scroll;
}

.mini-gap {
  height: 2em;
}

.green-dot {
  background-image: url("assets/images/green_dot_bg.png");
  background-repeat: repeat;
  background-size: cover;
}

.blue-dot {
  background-image: url("assets/images/blue_dot_bg.jpg");
  background-repeat: repeat;
  background-size: cover;
}

.purple-dot {
  background-image: url("assets/images/purple_dot_bg.jpg");
  background-repeat: repeat;
  background-size: cover;
}

.grey-dot {
  background-image: url("assets/images/grey_dot_bg.jpg");
  background-repeat: repeat;
  background-size: cover;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-book {
  height: 256px;
  width: 256px;
  object-fit: contain;
}

.loading-text {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 700;
  font-size: 2vh;
  letter-spacing: 0.15em;
  color: #0C8111;
}

.loading-text.large {
  font-size: 2.75vh;
}

.loading-text.blue {
  color: #1C67AC;
}

.loading-text.purple {
  color: #6F54B1;
}

.loading-text.grey {
  color: #5E5E5E;
}

.xlarge {
  font-size: 3.75vh;
}

@property --num {
  syntax: '<integer>';
  initial-value: 0;
  inherits: false;
}

.loading-counter-a {
  transition: --num 1s;
  counter-reset: num var(--num);
}

.loading-counter-a {
  animation: counter_a 1s ease-in-out;
  --num: 50;
}

.loading-counter-a::after {
  content: counter(num);
}

@keyframes counter_a {
  from {
    --num: 0;
  }

  to {
    --num: 50;
  }
}

.loading-counter-b {
  transition: --num 1s;
  counter-reset: num var(--num);
}

.loading-counter-b {
  animation: counter_b 1s ease-in-out;
  --num: 100;
}

.loading-counter-b::after {
  content: counter(num);
}

@keyframes counter_b {
  from {
    --num: 50;
  }

  to {
    --num: 100;
  }
}

.half-width {
  width: 50%;
}

.two-third-width {
  width: 65%;
}

.result-content-area {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 4em;
  max-width: 1280px;
}

img.trophy {
  height: 100%;
  width: 100%;
  max-width: 512px;
  max-height: 512px;
  object-fit: contain;
}

img.trophy.large {
  max-width: 640px;
  max-height: 640px;
}

.result-star {
  animation: fade-in 0.5s forwards;
  opacity: 0;
}

.delay-1 {
  animation-delay: 0.25s;
}

.delay-2 {
  animation-delay: 0.5s;
}

.delay-3 {
  animation-delay: 1s;
}

.delay-4 {
  animation-delay: 1.25s;
}

.flex {
  display: flex;
}

.flex-v {
  flex-direction: column;
}

.gap-s {
  gap: 0.5em;
}

.gap-m {
  gap: 1em;
}

.gap-l {
  gap: 1.5em;
}

.gap-xl {
  gap: 2em;
}

.gap-xxl {
  gap: 2.5em;
}

.result-ability-backdrop {
  background: white;
  box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 1.5em;
  max-width: 100%;
  box-sizing: border-box;
}

.ability-block {
  background-color: #FAF7ED;
  padding: 1em 2em;
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.15em;
  color: #333333;
  width: 302px;
  box-sizing: border-box;
}

.star-block {
  gap: 0.5em;
  align-items: center;
}

.navigate-button-s {
  width: 128px;
}

img.star {
  height: 28px;
  width: 28px;
}

.ability-word {
  word-break: keep-all;
  line-height: 36px;
  width: 84px;
}

.result-green-button:hover>img {
  mix-blend-mode: exclusion;
}

.bottom-border {
  border-bottom: 1.2px dashed #BFBFBF;
  margin-left: 72px;
  height: 0;
}

.result-ability-label {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.35em;
  color: #005A03;
  height: 12px;
  width: 310px;
  transform: translateY(-38px);
}

.result-ability-label.blue {
  color: #1C67AC;
}

.result-ability-label.purple {
  color: #6F54B1;
}

.space-around {
  justify-content: space-around;
}

.margin-h-m {
  margin: 0em 1em;
}

.p-r-s {
  padding-right: 0.5em;
}

.details-result-score-text {
  font-weight: 700;
  font-size: 25px;
  line-height: 37px;
  letter-spacing: 0.15em;
  color: #005A03;
}

.details-result-score-number {
  font-family: 'Helvetica Neue';
  color: #EB4F45;
  font-size: 96px;
  padding: 0 8px;
}

.details-result-score-like-img {
  height: 24px;
  width: 24px;
}

.details-result-score-rate {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15em;
  text-align: left;
  color: #1CA281;
  padding: 0 0.5em;
}

.helvetica {
  font-family: 'Helvetica Neue';
  font-size: 20px;
  padding: 0 0.5em;
}

.details-result-backdrop {
  background: #FFFFFF;
  border: 2.5px solid #95D346;
  box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  min-width: 512px;
  // padding: 2em;
  overflow: scroll;
  height: fit-content;
  max-width: 1280px;
}


.details-result-backdrop.blue {
  border: 2.5px solid #1969BF;
}

.details-result-backdrop.purple {
  border: 2.5px solid #8366B3;
}

.details-result-backdrop.grey {
  border: 2.5px solid #B0B0B0;
}

.details-result-backdrop.all-details {
  border: 2.5px solid #F6BC5A;
}

.details-result-backdrop.all-details.grey {
  border: 2.5px solid #898989;
}

.next-quiz-chat-box {
  display: flex;
  position: relative;
}

.next-quiz-chat-box-backdrop {
  background-color: white;
  padding: 1.5em 2em;
  border: 2px solid #FFBDA9;
  border-radius: 24px;
  box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.05);
  transform: translateX(-64px);
}

.next-quiz-chat-box-title {
  font-weight: 700;
  font-size: 19px;
  line-height: 28px;
  letter-spacing: 0.15em;
  color: #DE6D1C;
}

.next-quiz-chat-box-desc {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.15em;
  color: #333333;
}

.next-quiz-chat-box-char-parent {
  height: 64px;
  position: relative;
}

.next-quiz-chat-box-char {
  width: 156px;
  position: absolute;
  bottom: 0;
  right: 0;
  object-fit: contain;
  transform: translateX(16px);
  pointer-events: none;
}

.space-between {
  justify-content: space-between;
}

.right-point {
  position: absolute;
  bottom: 0;
  right: -10%;
  right: 48px;
  transform: translateY(16px);
}

.right-point-cover {
  background-color: white;
  position: absolute;
  bottom: 0;
  right: -10%;
  right: 48px;
  height: 20px;
  width: 40px;
  transform: translateY(0px);
}

.orange-dialog-divider {
  width: 100%;
  height: 1px;
  background-color: #F9D195;
  margin: 0.5em 0em;
}

.details-result-tab-parent {
  margin-left: 4em;
  display: flex;
  gap: 1em;
  cursor: pointer;
}

.tab-button-green {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.55em;
  color: #FFFFFF;
  padding: 0.25em 0.5em;
  background: #79C01E;
  border: 2px solid transparent;
  border-bottom: 0px;
  white-space: nowrap;
  border-radius: 8px 8px 0px 0px;
  text-indent: 0.55em;
  cursor: pointer;
}

.tab-button-green:hover {
  color: #79C01E;
  border: 2px solid #79C01E;
  border-bottom: 0px;
  background: transparent;
}

.tab-button-green.blue {
  background: #1969BF;
}

.tab-button-green.blue:hover {
  color: #1969BF;
  border: 2px solid #1969BF;
  border-bottom: 0px;
  background: transparent;
}

.tab-button-green.purple {
  background: #8366B3;
}

.tab-button-green.purple:hover {
  color: #8366B3;
  border: 2px solid #8366B3;
  border-bottom: 0px;
  background: transparent;
}


.tab-button-green.grey {
  background: #8366B3;
}

.tab-button-green.grey:hover {
  color: #B0B0B0;
  border: 2px solid #B0B0B0;
  border-bottom: 0px;
  background: transparent;
}

.tab-button-yellow {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.55em;
  color: #FFFFFF;
  padding: 0.25em 0.5em;
  background: #EBAB41;
  border: 2px solid transparent;
  border-bottom: 0px;
  white-space: nowrap;
  border-radius: 8px 8px 0px 0px;
  text-indent: 0.55em;
  cursor: pointer;
}

.tab-button-yellow.grey {
  background: #898989;
}

.tab-button-yellow.grey:hover {
  color: #898989;
  border: 2px solid #898989;
  border-bottom: 0px;
  background: white;
}

.tab-button-yellow:hover {
  color: #EBAB41;
  border: 2px solid #EBAB41;
  border-bottom: 0px;
  background: transparent;
}

.details-result-dialog-parent {
  min-width: 192px;
}

.self-align-center {
  align-self: center;
}

.details-pencil {
  position: fixed;
  right: 32px;
  align-self: flex-start;
  margin-left: 2em;
  transform: translateY(min(204px, calc(100vh - 204px - 321px)));
  // bottom: 0;
}

.question-with-answer-explanation-backdrop {
  background: #F2F9ED;
  border-radius: 2px;
  padding: 12px 12px 12px 24px;
  width: 100%;
  box-sizing: border-box;
}

.question-with-answer-explanation-backdrop.all-details {
  background: #FDFBF1;
}

.question-with-answer-explanation-title-parent {
  display: flex;
  justify-content: space-between;
}

.question-with-answer-explanation-title {
  font-family: 'edukai';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.18em;
  color: #448047;
  width: fit-content;
  // margin-bottom: 1em;
}

.question-with-answer-explanation-title.all-details {
  color: #8A5E37;
}

.question-with-answer-explanation-answer {
  font-family: 'edukai';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.18em;
  color: black;
  width: fit-content;
  margin-top: 1em;
}

.question-with-answer-explanation-description {
  font-family: 'edukai';
  white-space: pre-wrap;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 134.5%;
  display: flex;
  align-items: center;
  letter-spacing: 0.18em;
  color: #333333;
  width: fit-content;
  margin-top: 1em;
}

.question-with-answer-explanation-source {
  font-family: 'Helvetica Neue';
  white-space: pre-wrap;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 134.5%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  letter-spacing: 0.18em;
  color: #3D7FB7;
  // width: fit-content;
  margin-top: 1em;
}

.button {
  padding: 0.5em 1em;
  cursor: pointer;
}

.dotted-divider-h {
  width: 100%;
  height: 1px;
  border-bottom: 0.8px dashed #BFBFBF;
}

.max-height-anim {
  animation: grow-max-height 0.2s forwards;
}

@keyframes grow-max-height {
  0% {
    max-height: 0px;
  }

  100% {
    max-height: 1024px;
  }
}

.result-page-parent {
  width: 100vw;
  padding: 64px;
  box-sizing: border-box;
}

.full-wh {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 1280px) {
  .result-content-area {
    flex-direction: column;
    gap: 0em;
    justify-content: unset;
  }

  .half-width {
    width: 100%;
  }

  .two-third-width {
    width: 100%;
  }

  .result-page-parent {
    padding: 16px;
  }

  #content-title.large {
    font-size: 32px;
  }

  .ability-block {
    padding: 0.5em 1em;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.05em;
  }

  .result-green-button {
    font-size: 14px;
    height: 36px;
    padding: 0px 16px;
  }

  .gap-xxl {
    gap: 1em;
  }

  .result-ability-label {
    font-size: 18px;
    white-space: nowrap;
    letter-spacing: 0.2em;
  }

  img.star {
    width: 28px;
    height: 28px;
  }

  .result-ability-label {
    width: 215px;
  }
}

.center-v {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.top-bar-icon {
  display: none;
  height: 100%;
  padding: 1em;
  box-sizing: border-box;
  object-fit: contain;
}

.button-disable {
  pointer-events: none;
  filter: grayscale(1);
  opacity: 0.5;
}

@media only screen and (max-width: 1000px) {
  .top-bar-title {
    display: none;
  }

  .top-bar-icon {
    display: block;
  }
}

.select-paper-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #F4F8FA;
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: 0.2em;
  border-radius: 4em;
  // margin-left: 1em;
  padding: 0.4em;
  padding-right: 4em;
  padding-left: 1em;
  font-size: 14px;
  font-family: "Noto Sans TC";
  font-weight: 700;
  cursor: pointer;
  width: 128px;
}

select.select-green {
  border: 1px solid #56A920;
  color: #56A920;
  background-image: url("./assets/images/dropdown_arrow_green.svg");
}

select.select-blue {
  border: 1px solid #1969BF;
  color: #1969BF;
  background-image: url("./assets/images/dropdown_arrow_blue.svg");
}

select.select-purple {
  border: 1px solid #8366B2;
  color: #8366B2;
  background-image: url("./assets/images/dropdown_arrow_purple.svg");
}

select.select-grey {
  border: 1px solid #C28765;
  color: #C28765;
  background-image: url("./assets/images/dropdown_arrow_grey.svg");
}

.select-paper-difficulty-container {
  display: flex;
  gap: 0.5em;
  justify-content: center;
  align-items: center;
}

.difficulty-star-default {
  color: #DBDBDB;
}

.color-green {
  color: #56A920;
}

.color-blue {
  color: #1969BF;
}

.color-purple {
  color: #8366B2;
}

.color-grey {
  color: #B0B0B0;
}

.page-not-found-container {
  width: 100vw;
  padding: 64px;
  box-sizing: border-box;
  gap: 2em;
  // flex-direction: column;
}

.page-not-found-title {
  font-family: "Noto Sans TC";
  font-size: 64px;
}

.half-black-text {
  color: #00000080;
}

.question-with-answer-question>p {
  margin: unset;
}

// remove all font-family for any span in question-with-answer-question's children or grandchildren
.question-with-answer-question>*>span {
  font-family: unset !important;
  font-size: unset !important;
  color: unset !important;
}

.question-with-answer-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 1em;
  width: 100%;
}

.question-with-answer-subcontainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 1em;
  padding: 2.5em 2.5em 2.5em 0.5em;
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
}

.w100 {
  width: 100%;
}

.question-with-answer-context {
  font-family: 'edukai';
  font-weight: 400;
  font-size: 22px;
  line-height: 135%;
  letter-spacing: 0.15em;
  color: #333333;
  white-space: pre-wrap;
  overflow: hidden scroll;
  padding-right: 0.75em;
  // padding: 2.5em 0.5em 2.5em 2.5em;
  // max-height: 500px;
}

.question-with-answer-context.single {
  overflow: unset;
  padding-right: unset;
  margin-bottom: unset;
}

.question-with-answer-expand-collapse-article-container {
  display: flex;
  flex-direction: column;
  padding: 2.5em 0.5em 0em 2.5em;
  box-sizing: border-box;
}

.question-with-answer-expand-collapse-article {
  cursor: pointer;
  color: #8373A5;
  font-family: "Noto Sans TC";
  display: flex;
  align-items: center;
  gap: 0.5em;
  align-self: flex-end;
  justify-self: flex-start;
}

.no-scroll {
  overflow: hidden;
}

.next-page-button {
  margin-top: 14px;
  width: fit-content;
}

.scroll-v {
  overflow: hidden scroll;
}

.no-letter-spacing {
  letter-spacing: 0;
}

// * {
//   outline: 1px solid red;
// }


.bounce-up {
  animation: bounce-up 1s ease-in-out;
}

@keyframes bounce-up {
  0% {
    transform: translateY(150px);
  }

  50% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(0);
  }
}

.fade-in-after-1s {
  animation: fade-in 1.5s ease-in-out;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  75% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.wiggle {
  animation: wiggle 0.75s 2 ease-in-out 1.75s;
}

@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }

  5% {
    transform: rotate(5deg);
  }

  10% {
    transform: rotate(0deg);
  }

  15% {
    transform: rotate(-5deg);
  }

  20% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(5deg);
  }

  30% {
    transform: rotate(0deg);
  }

  35% {
    transform: rotate(-5deg);
  }

  40% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.question-with-answer-category-tag {
  background: #1781BC;
  border: 2px solid #74AFD0;
  box-sizing: border-box;
  border-radius: 6px;
  color: white;
  font-family: "Noto Sans TC";
  padding: 0.25em 1em;
}

.m-b-s {
  margin-bottom: 0.5em;
}

.m-b-m {
  margin-bottom: 1em;
}

.disabled-button {
  pointer-events: none;
  opacity: 0.5;
  filter: saturate(0);
}

.score-description {
  padding-top: 1em;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.1em;
  color: rgb(120, 70, 24);
  z-index: 10;
  text-indent: 0.22em;
  position: inherit;
}

.score-highlight {
  font-size: 48px;
  color: #DA8333;
  padding: 0em 0.1em;
}

.flat-button {
  background: #1969BF;
  border: 1px solid #57ACDC;
  padding: 1em;
  width: 100%;
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  justify-content: center;
  letter-spacing: 0.6em;
  text-indent: 0.6em;
  color: white;
  align-items: center;
  cursor: pointer;
}

.flat-button.compact {
  padding: 0.25em 0.5em;
}

.flat-button.fit {
  width: fit-content;
  padding: 0.25em 2em;
}

.flat-button.mini {
  padding: 0em 0.5em;
  font-size: 14px;
  width: fit-content;
  min-width: fit-content;
}

.flat-button.curve {
  border-radius: 64px;
}

.flat-button.mini.curve {
  border-radius: 6px !important;
}

.flat-button.active {
  border: 1px solid #74AFD0;
  background: #1781BC;
  color: white;
}

.flat-button:hover {
  background-color: #1781BC20;
  color: #1781BC;
}

.flat-button.active:hover {
  background-color: #1781BC;
  border: 1px solid #1781BC;
}

.flat-button-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.flat-button-container.fit {
  width: fit-content;
}

.mock-paper-details-analysis-wrapper {
  background: #FFFFFF;
  box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 2em;
  margin: 2em;
  gap: 1em;
  // display: grid;
  // grid-template-columns: 1fr 1fr 1fr;
  display: flex;
  overflow-x: scroll;
}

.mock-paper-details-analysis {
  width: 33%;
  height: 384px
}

.mock-paper-details-analysis-title {

  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  /* or 111% */

  text-orientation: upright;
  writing-mode: vertical-lr;

  color: #5E5E5E;
}

.quote {
  text-align: end;
  font-family: "Noto Sans TC", sans-serif;
  font-size: 0.75em;
}

.unset-a {
  color: inherit;
  text-decoration: none;
}

.text-button-hover-menu {
  display: none;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  right: 1em;
  width: fit-content;
  gap: 0.5em;
  padding: 0.5em 1em;
  background: #FAF8EF;
  border-radius: 5px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  // transform: translateY(-1em);
}

.text-button-wrapper:hover .text-button-hover-menu {
  display: flex;
}

.text-button-hover-menu-item-wrapper {
  padding: 0.75em 1em;
  border-bottom: #C3C3C3 1px dashed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2em;
  width: 100%;
  box-sizing: border-box;
}

.text-button-hover-menu-item-wrapper.last-child {
  border-bottom: unset;
}

.text-button-hover-menu-item {
  color: #333333;
  letter-spacing: 0.05em;
  display: flex;
  justify-content: space-between;
  gap: 1em;
  width: 100%;
}

.text-button-hover-menu-item.disabled {
  filter: grayscale(100%);
}

.text-button-hover-menu-item:hover {
  font-weight: 500;
}

.text-button-hover-menu-item-wrapper:hover img {
  animation: wiggle-left-right 0.5s ease-in-out infinite;
}

.text-button-hover-menu-navigate-icon {
  height: 32px;
  width: 32px;
}

@keyframes wiggle-left-right {
  0% {
    transform: translateX(0px);
  }

  50% {
    transform: translateX(5px);
  }

  100% {
    transform: translateX(0px);
  }
}

.noclick {
  pointer-events: none;
}

.a-clear {
  text-decoration: unset;
  color: unset;
}

.basic-result-page-parent {
  background-size: cover;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.clear-p>p {
  margin: unset;
}

.bg-fit {
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.preview-quiz-tips {
  position: fixed;
  left: 0px;
  bottom: 0px;
  padding: 16px;
  pointer-events: none;
  opacity: 0.25;
  font-weight: 700;
  font-size: 64px;
}

.maintenance {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
  color: #333333;

  .maintenance-text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 24px;
    font-weight: 700;
    color: #333333;
  }
}